import axios from 'axios'
import router from '@/router'
import { getToken } from './auth'
import { Notify } from 'vant'

const baseURL: string = process.env.VUE_APP_BASE_URL as string
const tokenKeyByHeader = process.env.VUE_APP_TOKEN as string

export interface AxiosResponse<T> {
  status: number
  statusText: string
  headers: any
  config: any
  request?: any
  data: T,
  results?: any
  totalPages?: number
}

export interface ResponseWrapper<R> {
  data: {
    code: number
    msg: string
    success: string
    data: R
    results?: any
    totalPages?: number
  }
  status: number
}

const cleanApp = async () => {
  try {
    //TODO 清空登录信息
    console.log(router)
  } catch (error) {
    console.error(error)
  } finally {
    console.log(11)
    // window.location.reload()
  }
}

const ERROR_HANDLER: any = {
  401: () => {
    Notify({
      type: 'warning',
      message: 'No permission',
      onClose: cleanApp,
    })
  },
  500: (msg: string) => {
    Notify({
      type: 'danger',
      message: msg,
    })
  },
  def: (msg: string) => {
    Notify({
      type: 'danger',
      message: msg,
    })
  },
}

function errorHandler(code: Number, msg: any) {
  const handler = ERROR_HANDLER[`${code}`] || ERROR_HANDLER.def
  handler(msg)
}

const NO_LOGIN_CODE = [101008]
function isRequestSuccess(status: number): boolean {
  return status >= 200 && status < 300
}

const requester = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  },
})

requester.interceptors.request.use(
  async config => {
    const token = getToken()
    if (token) {
      Object.assign(config.headers || {}, {
        [tokenKeyByHeader]: token,
      })
    }
    return config
  },
  error => {
    console.log('request error', error)
  },
)

requester.interceptors.response.use(
  response => {
    const { data, status } = response
    if(data.code == 401) {
      router.push('/')
      return
    }
    if (isRequestSuccess(status)) {
      const { code, msg, data: result, success } = data
      if (success) {
        return result
      } else {
        errorHandler(code, msg)
        return Promise.reject(data)
      }
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    const { code, data, statusText } = error.response
    errorHandler(code, data || statusText)

    return Promise.reject(error)
  },
)

export default requester
