import { createApp } from "vue";
import { ConfigProvider } from 'vant'
import pinia from '@/stores'
import App from "./App.vue";
import router from "./router";
import '@/utils/useLogin'
import './styles/main.less'
import 'vant/es/toast/style'
import 'vant/es/dialog/style'
import 'vant/es/notify/style'
import 'vant/es/image-preview/style'

const app = createApp(App)

app.use(pinia).use(router).use(ConfigProvider)

app.mount('#app')
