import './style.less'
import { WalletLogin } from '../../index'
import IMG_CLOSE from '../../assets/img/close.png'
import { Langs, LoginModalOptions, SocialOption } from '../../interface'
import { messages } from '../../i18n'
import { WalletMeta } from '@particle-network/connect'
import { parseString } from '../../utils'
import metamaskLeft from './../../assets/img/MetaMask1.png'
import metamaskRight from './../../assets/img/MetaMask2.png'
import WalletConnectLeft from './../../assets/img/WalletConnect1.png'
import WalletConnectRight from './../../assets/img/WalletConnect2.png'
import WalletNameLeft from './../../assets/img/WalletName1.png'
import WalletNameRight from './../../assets/img/WalletName2.png'

const ROW_BTN = 'row-btn'
const QR_BTN = 'qr-btn'
class LoginModal extends HTMLElement {
  constructor() {
    super()
  }
  loginModal: HTMLElement | null = null

  lang: Langs = 'en'
  data: LoginModalOptions | null = null

  connectedCallback() {
    // 首次被插入文档时调用
    this.loginModal = this.createDom()
  }

  disconnectedCallback() {
    // dom被删除时调用
  }

  adoptedCallback() {
    // dom被移动到新文档时调用
  }
  static get observedAttributes() {
    return ['lang', 'data'] // 监听config
  }

  attributeChangedCallback(name: string, oldValue: any, newValue: any) {
    // 自身属性被增删改查时调用
    // console.log(name, oldValue, newValue)
    let res = newValue
    try {
      res = JSON.parse(res)
    } catch (error) { }
    if (this[name] !== res) {
      this[name] = res
    }
  }

  get msg() {
    return WalletLogin.getInstance().i18nMap
  }
  createDom() {
    const appHtml = this.createAppHtml()
    const socialHtml = this.createSocialHtml()
    const { isShowSocialConnect } = WalletLogin.getInstance()
    const name = WalletLogin.getInstance()._loginConfig.getSignName()
    const html = `
      <div class="__login-main">
          <div class="__login-main-title" >
            ${this.msg.title}${name}
          </div>
          <img src="${IMG_CLOSE}"  class="__login-main-close-btn" />
          <div class="__login-main-decs" >
            ${this.msg.decs}
          </div>
          ${appHtml}
          ${isShowSocialConnect ? socialHtml : `<div style='height:10px'></div>`}
      </div>
    `
    const el = document.createElement('div')
    el.setAttribute('class', '__login-modal')
    el.innerHTML = html
    this.append(el)

    // 直接连接
    const btns = document.querySelectorAll(`.${ROW_BTN}`)
    for (let i = 0; i < btns.length; i++) {
      const item = btns[i]
      item.addEventListener('click', (e) => {
        const row = item.getAttribute('data-row')
        WalletLogin.getInstance().connect(parseString(row))
      })
    }

    // 关闭
    const closeBtn = document.querySelector('.__login-main-close-btn')
    closeBtn?.addEventListener('click', (e) => {
      WalletLogin.getInstance().closeModal()
      document.body.style.overflow = 'auto'
    })
    return el
  }

  isQRCode(name: string): boolean {
    return ['WalletConnect'].includes(name)
  }
  setIcon(id: string) {
    let obj: any = {}
    id = id.toLocaleLowerCase()
    switch (id) {
      case 'metamask':
        obj = {
          icon: [metamaskLeft, metamaskRight],
          className: '__login-btn-metamask'
        }
        break;
      case 'web3modal':
        obj = {
          icon: [WalletConnectLeft, WalletConnectRight],
          className: '__login-btn-web3modal'
        }
        break;
      default:
        obj = {
          icon: [WalletNameLeft, WalletNameRight],
          className: '__login-btn-WalletName'
        }
        break;
    }
    return obj
  }
  createAppHtml() {
    let res = ''
    this.data?.app.forEach((t: WalletMeta) => {
      const { name, id } = t
      const _name = name === 'Web3Modal' ? 'WalletConnect' : name
      // const btnClass = ROW_BTN
      res += `
       <div
          data-row=${JSON.stringify(t)}
          class="${ROW_BTN} ${this.setIcon(id).className} __login-item __btn-dynamic"
       >
        <img src="${this.setIcon(id).icon[0]}" class="__login-item-img"/>
        <div class="__login-item-b">${_name}</div>
        <img src="${this.setIcon(id).icon[1]}" class="__login-item-imgRight"/>
      </div>
      `
    })
    return `
          <div class="__login-main-app">
          ${res}
          </div>
    `
  }

  isPhoneItem(row) {
    return row.preferredAuthType === 'phone'
  }

  isSocialItem(row) {
    return row.preferredAuthType !== 'phone'
  }

  createSocialHtml() {
    let res = ''
    this.data?.social.filter(this.isSocialItem).forEach((t: SocialOption) => {
      const { icon, name } = t
      res += `
       <div
          data-row=${JSON.stringify(t)}
          class="${ROW_BTN} __login-social-item __btn-dynamic"
        >
          <img src="${icon}" class="__login-social-item-img" />
          <div class="__login-item-b">${name}</div>
        </div>
      `
    })
    return `
    <div class="__login-main-app small">
      <div class="__login-main-app__title">
        <div></div>
        <span>${this.msg.logInWith}</span>
        <div></div>
      </div>
      <div class="__login-social">${res}</div>
      <phone-input lang="${this.lang}"></phone-input>

    </div>
    `
  }
}

window.customElements.define('login-modal', LoginModal)
