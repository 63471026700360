import './style.less'
import IMG_LOADING from '../../assets/img/loading.png'
import IMG_ARROW_RIGHT from '../../assets/img/arrow-right.png'
import { WalletLogin } from '../../core'
export interface LoadingOptions {
  title?: string
  content?: string
  btn?: string
  showBtn?: boolean
}
export class LoadingView {
  static instance: LoadingView
  el = document.createElement('div')
  titleEl: HTMLElement | null
  contentEl: HTMLElement | null
  btnEl: HTMLElement | null
  btnContentEl: HTMLElement | null
  constructor() {
    const html = `
      <div class="__loading-view">
        <img src="${IMG_LOADING}" class="__loading-view-img"/>
        <div class="__loading-view-title ">
        </div>
        <div class="__loading-view-content">
        </div>
        <div class="__loading-view-btn">
           <span class="__loading-view-btn-content"></span>
          <img src="${IMG_ARROW_RIGHT}"/>
        </div>
      </div>
    `
    this.el.style.display = 'none'
    this.el.innerHTML = html
    document.body.appendChild(this.el)

    this.titleEl = document.querySelector('.__loading-view-title')
    this.contentEl = document.querySelector('.__loading-view-content')
    this.btnEl = document.querySelector('.__loading-view-btn')
    this.btnContentEl = document.querySelector('.__loading-view-btn-content')
    this.btnEl!.addEventListener('click', () => {
      WalletLogin.getInstance().continueSign()
    })
  }
  static getInstance() {
    if (!LoadingView.instance) {
      LoadingView.instance = new LoadingView()
    }
    return LoadingView.instance
  }
  open(opt: LoadingOptions = {}) {
    const { title, content, btn, showBtn } = opt
    this.titleEl!.innerHTML = title || ''
    this.contentEl!.innerHTML = content || ''
    if (showBtn) {
      this.btnContentEl!.innerHTML = btn || ''
      this.btnEl!.style.display = 'block'
    }
    this.el.style.display = 'block'
  }
  update(opt: LoadingOptions = {}) {
    const { title, content, btn, showBtn } = opt
    this.titleEl!.innerHTML = title || ''
    this.contentEl!.innerHTML = content || ''
    if (showBtn) {
      this.btnContentEl!.innerHTML = btn || ''
      this.btnEl!.style.display = 'block'
    }
  }
  close() {
    this.btnEl!.style.display = 'none'
    this.el.style.display = 'none'
  }
}
