import { walletService } from '@/api';
import { setup } from '@/submodules/walletLogin/index';
import { Notify } from 'vant';
import Web3 from 'web3';
const isDev = ['development'].includes(process.env.NODE_ENV);
const SIGN_NAME = 'NFT Proof';
const props = {
  isDev,
  loginConfig: {
    getRandom: walletService.getRadomNoByAddress,
    getSignName: () => SIGN_NAME,
    login: async (context, connectReturn) => {
      try {
        const address = context.address;
        const signature = connectReturn.signature;
        const loginBody = {
          address,
          signature,
          type: 0
        };
        const loginRes = await walletService.login(loginBody);
        const userStore = useUserStore();
        userStore.SET_INFO(loginRes.info);
        userStore.SET_TOKEN(loginRes.token);
        userStore.SET_ADDRESS(address);
        console.warn('success', address, loginRes);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    }
  },
  logout: () => {
    const userStore = useUserStore();
    userStore.CLEAR_USER();
  },
  isAutoConnectByCache: async () => true,
  on: {
    chainChanged: (message, networkId) => {
      console.log(111, 'networkChanged', networkId);
      Notify({
        type: 'warning',
        duration: 0,
        message,
        position: 'bottom'
      });
    },
    error: e => {
      Notify({
        type: 'warning',
        message: e?.message || JSON.stringify(e)
      });
      console.log(e.message);
    }
  }
};
setup({
  props,
  InjectWeb3: Web3,
  i18n: {
    en: {
      title: "Welcome to NFTProof"
    }
  }
});