import Cookies from 'js-cookie'

export const Local = {
  set(key: string, val: any) {
    if (typeof val === 'object') {
      val = JSON.stringify(val)
    }
    localStorage.setItem(key, val)
  },

  get(key: string): any {
    let res: any = localStorage.getItem(key)
    try {
      res = JSON.parse(res)
      // eslint-disable-next-line prettier/prettier
    } catch (error) {}
    return res || null
  },

  remove(key: string) {
    localStorage.removeItem(key)
  },

  clear() {
    localStorage.clear()
  },
}

export const Session = {
  set(key: string, val: any) {
    if (typeof val === 'object') {
      val = JSON.stringify(val)
    }
    sessionStorage.setItem(key, val)
  },

  get(key: string): any {
    let res: any = sessionStorage.getItem(key)
    try {
      res = JSON.parse(res)
      // eslint-disable-next-line prettier/prettier
    } catch (error) {}
    return res || null
  },

  remove(key: string) {
    sessionStorage.removeItem(key)
  },

  clear() {
    sessionStorage.clear()
  },
}

export const Cookie = {
  set(key: string, val: any, args?: object) {
    if (typeof val === 'object') {
      val = JSON.stringify(val)
    }
    Cookies.set(key, val, args)
  },

  get(key: string): any {
    let res: any = Cookies.get(key)
    try {
      res = JSON.parse(res)
      // eslint-disable-next-line prettier/prettier
    } catch (error) {}
    return res || null
  },

  getAll(): any {
    return Cookies.get()
  },

  remove(key: string, args?: object) {
    Cookies.remove(key, args)
  },
}
