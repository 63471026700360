import {
  ConnectConfig,
  metaMask,
  walletconnect,
  injectedEVM,
  rainbow,
  web3Modal,
  argent,
  omni
} from '@particle-network/connect'
import { SocialOption, WalletLoginConfig, WalletLoginEnv } from '../interface'
import IMG_FACEBOOK from '../assets/img/facebook.png'
import IMG_GOOGLE from '../assets/img/google.png'

const wallets = [
  injectedEVM(),
  rainbow(),
  web3Modal(),
  argent(),
  omni(),
  metaMask(),
  walletconnect()
]
const CONFIG: {
  [key: string]: {
    connect: ConnectConfig
    env: WalletLoginEnv
  }
} = {
  DEV: {
    connect: {
      projectId: '750cfc62-8f06-43e1-b8cb-7ed270dc2278',
      clientKey: 'cQgtg4U3X3pxmOuhUG5pbVaDbCGX40aZBJoHXZzk',
      appId: 'aa00cf20-4152-4831-95ed-cec449e160f7',
      chains: [{
        name: 'Ethereum',
        id: 5
      }],
      particleWalletEntry: {
        displayWalletEntry: false
      },
      wallets
    },
    env: {
      id: 5
    }
  },
  PROD: {
    connect: {
      projectId: '750cfc62-8f06-43e1-b8cb-7ed270dc2278',
      clientKey: 'cQgtg4U3X3pxmOuhUG5pbVaDbCGX40aZBJoHXZzk',
      appId: 'aa00cf20-4152-4831-95ed-cec449e160f7',
      chains: [{
        name: 'Ethereum',
        id: 1
      }],
      particleWalletEntry: {
        displayWalletEntry: false
      },
      wallets
    },
    env: {
      id: 1
    }
  }
}

export function getDefaultConfig(isDev: boolean): WalletLoginConfig {
  const key = isDev ? 'DEV' : 'PROD'
  return CONFIG[key]
}

export const SOCIAL_OPTIONS: SocialOption[] = [
  {
    preferredAuthType: 'facebook',
    supportAuthTypes: 'all',
    socialLoginPrompt: 'consent',

    name: 'Facebook',
    icon: IMG_FACEBOOK,
    isSocial: true
  },
  {
    preferredAuthType: 'google',
    supportAuthTypes: 'all',
    socialLoginPrompt: 'consent',

    name: 'Google',
    icon: IMG_GOOGLE,
    isSocial: true
  }
]
