import requester from '@/utils/request'

export const userService = {
  qrGenerate: (data: any) =>
    requester({
      url: '/rule/v1/qr/generate',
      method: 'post',
      data,
    }),
  balance: (data: any) =>
    requester({
      url: '/wallet/v1/balance/query',
      method: 'post',
      data,
    }),
    globalNick: (data: any) =>
    requester({
      url: '/wallet/v1/global/nick',
      method: 'post',
      data,
    }),
}
