export const defaultCountriesData = [
  ["Singapore", "sg", "65"],
  ["United States", "us", "1"],
  ["Malaysia", "my", "60"],
  ["Australia", "au", "61"],
  ["Indonesia", "id", "62"],
  ["Philippines", "ph", "63"],
  ["Thailand (ไทย)", "th", "66"],
  ["Japan (日本)", "jp", "81"],
  ["South Korea (대한민국)", "kr", "82"],
  ["Vietnam (Việt Nam)", "vn", "84"],
  ["China (中国)", "cn", "86"],
  ["Hong Kong (香港)", "hk", "852"],
  ["Taiwan (台灣)", "tw", "886"],
];