import './style.less'
import { WalletLogin } from '../../index'
import { Langs,  SocialOption } from '../../interface'
import { messages } from '../../i18n'
import { WalletMeta } from '@particle-network/connect'
import { parseString } from '../../utils'

class SignModal extends HTMLElement {
  constructor() {
    super()
  }
  loginModal: HTMLElement | null = null
  lang: Langs = 'en'

  connectedCallback() {
    // 首次被插入文档时调用
    this.createDom()
  }

  disconnectedCallback() {
    // dom被删除时调用
  }

  adoptedCallback() {
    // dom被移动到新文档时调用
  }

  static get observedAttributes() {
    return ['lang', 'data'] // 监听config
  }

  attributeChangedCallback(name: string, oldValue: any, newValue: any) {
    // 自身属性被增删改查时调用
    // console.log(name, oldValue, newValue)
    let res = newValue
    try {
      res = JSON.parse(res)
    } catch (error) {}
    if (this[name] !== res) {
      this[name] = res
    }
  }

  get msg() {
    return messages[this.lang]
  }
  createDom() {
    const el = document.createElement('div')
    el.classList.add('sign-modal')
    el.innerHTML = `
    <div class="sign-modal-l">
      <div class="sign-modal-l__title">
        Approve Pop-up
      </div>
      <div class="sign-modal-l__content">
        Click Continue to  complete the signature or transaction
      </div>
    </div>
    <div class="sign-modal-r">
        Continue
    </div>
    `
    this.append(el)
    const btn = document.querySelector('.sign-modal-r')
    btn?.addEventListener('click', () => {
      WalletLogin.getInstance().continueSign()
      el.remove()
    })
  }
}

window.customElements.define('sign-modal', SignModal)
