export default {
  title: 'Welcome to ',
  decs: 'Please select sign-in method',
  send: 'Send',
  popular: 'Popular',
  logInWith: 'Or connect using',

  netWorkErrorByDev:
    'Your wallet is connected to the unexpected network, please switch to network to Goerli',
  netWorkErrorByProd:
    'Your wallet is connected to the unexpected network, please switch to network to Mainnet',
  switchAccount: 'Switch Account',
  signIn: 'Sign In',
  signInContent: 'Please sign your signature in your wallet to continue login',
  logout: 'Logout...',
  approvePopUp: 'Approve Pop-up',
  approvePopUpMsg: 'Click continue to complete the signature or transaction.',
  continue: 'Continue'
}
