import { Cookie } from './storage'
const TOKEN_KEY = process.env.VUE_APP_TOKEN as string
const ADDRESS_KEY = process.env.VUE_APP_ADDRESS as string
const INFO_KEY = process.env.VUE_APP_INFO as string
// TOKEN

export const getToken = (): string | null => {
  return Cookie.get(TOKEN_KEY)
}

export const setToken = (v: string, args?: object): void => {
  Cookie.set(TOKEN_KEY, v, args)
}

export const removeToken = () => {
  Cookie.remove(TOKEN_KEY)
}

// address
export const getAddress = (): string => {
  return Cookie.get(ADDRESS_KEY) || ''
}

export const setAddress = (v: string, args?: object): void => {
  Cookie.set(ADDRESS_KEY, v, args)
}
export const removeAddress = () => {
  Cookie.remove(ADDRESS_KEY)
}

// info
export const getInfo = (): string => {
  return Cookie.get(INFO_KEY) || ''
}

export const setInfo = (v: string, args?: object): void => {
  Cookie.set(INFO_KEY, v, args)
}
export const removeInfo = () => {
  Cookie.remove(INFO_KEY)
}
export const setNetwork = (v: string, args?: object): void => {
  Cookie.set('Network', v, args)
}
