import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import { useUserStore } from '@/stores/modules/user'
import pinia from '@/stores'
import 'nprogress/nprogress.css'

const routes = [
  {
    path: '/',
    name: 'Start',
    meta: {
      title: '',
    },
    component: () => import('@/views/Start.vue'),
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/Home/index.vue'),
  },
  {
    path: '/verify-history',
    name: 'VerifyHistory',
    component: () => import('@/views/VerifyHistory/index.vue'),
  },
  {
    path: '/NFT-details',
    name: 'NFTDetails',
    component: () => import('@/views/NFTDetails/index.vue'),
  },
]
const router = createRouter({
  history: createWebHistory(),
  routes,
})
router.beforeEach((to, from, next) => {
  NProgress.start()
  if (to.meta.title) {
    document.title = typeof (to.meta.title) === 'string' ? to.meta.title : ''
  }
  const store = useUserStore(pinia)
  if (store.isLogin) {
    next()
  } else {
    if (to.name === 'Start') {
      next()
    }
    else {
      next({
        path: "/",
        replace: true
      })
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
