import requester from '@/utils/request'
export const homeService = {
  // 通过钱包地址获取所有activity
  getActivity(data: any) {
    // all network chainid是-1， 不传参数
    if(data.chain === -1){
      delete data.chain
    }
    return requester({
      url: '/verifier/v1/wallet/activity',
      method: 'post',
      data,
    })
  },
  // 通过钱包地址获取链上所有NFT
  getNFTS(data: any) {
    // all network chainid是-1， 不传参数
    if(data.chain === -1){
      delete data.chain
    }
    return requester({
      url: '/verifier/v1/wallet/nfts',
      method: 'post',
      data,
    })
  },
  // NFT详情
  nftDetails(params: any) {
    return requester.get(`/nft/commodity/v1/commodity/query/${params.id}`)
  },
  // 验证记录列表
  historyList(params: any) {
    return requester.post('/rule/history/v1/role/query', params)
  },
}
