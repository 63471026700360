export default {
  title: '欢迎来到',
  decs: '请选择登录方式',
  send: '发送',
  popular: '最受欢迎的',
  logInWith: '或使用',
  netWorkErrorByDev: '您的钱包已连接到其他网络，请切换到网络到Goerli',
  netWorkErrorByProd: '您的钱包已连接到其他网络，请切换到网络到Mainnet',
  switchAccount: '切换账户',
  signIn: '登录中...',
  signInContent: '请在钱包中签名以继续登录',
  logout: '退出中...',
  approvePopUp: '允许弹出窗口',
  approvePopUpMsg: '单击“继续”以完成签名或交易。',
  continue: '继续'
}
