import {
  getAddress,
  setAddress,
  getToken,
  setToken,
  removeToken,
  removeAddress,
  setInfo,
  getInfo,
  removeInfo,
  setNetwork
} from '@/utils/auth'
import { defineStore } from 'pinia'
export const useUserStore = defineStore('user', {
  state: () => {
    return {
      info: getInfo() || null,
      address: getAddress(),
      token: getToken(),
      network: -1,
    }
  },
  getters: {
    isLogin: (state): boolean => {
      // 完全登录时  用户信息和钱包地址都存在
      return !!(state.address && state.token && state.info)
    },
  },
  actions: {
    SET_INFO(data: any) {
      this.info = data
      setInfo(data)
    },
    SET_TOKEN(data: string) {
      this.token = data
      setToken(data)
    },
    CLEAR_INFO() {
      this.info = null
      removeInfo()
    },
    CLEAR_TOKEN() {
      this.token = ''
      removeToken()
    },
    SET_ADDRESS(data: string) {
      setAddress(data)
      this.address = data
    },
    CLEAR_ADDRESS() {
      this.address = ''
      removeAddress()
    },
    CLEAR_USER() {
      this.CLEAR_INFO()
      this.CLEAR_TOKEN()
      this.CLEAR_ADDRESS()
    },
    SET_NETWORK(data: number) {
      this.network = data
      setNetwork(data.toString())
    },
  },
})
