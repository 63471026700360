import './style.less'
import '../../assets/css/flag.css'
import IMG_ARROW from '../../assets/img/arrow.png'
import { Langs, WalletLogin } from '../../index'
import { defaultCountriesData } from './all-countries'

import { messages } from '../../i18n'
import { parseString } from '../../utils'
const reg = /^\d{8,14}$/
// <div class="flag:US" />
interface Current {
  country: string
  prefix: string
}
class PhoneInput extends HTMLElement {
  constructor() {
    super()
  }
  lang: Langs = 'en'

  ulEl: HTMLElement | null = null
  inputEl: HTMLElement | null = null
  countryBtnEl: HTMLElement | null = null
  el: HTMLElement | null = null
  _current: Current = {
    country: 'sg',
    prefix: '+65'
  }

  get current(): Current {
    return this._current
  }

  set current(v: Current) {
    this._current = v
    this.changePage()
  }

  connectedCallback() {
    // 首次被插入文档时调用
    this.el = this.createDom()
  }

  disconnectedCallback() {
    // dom被删除时调用
  }

  adoptedCallback() {
    // dom被移动到新文档时调用
  }
  static get observedAttributes() {
    return ['lang'] // 监听config
  }

  attributeChangedCallback(name: string, oldValue: any, newValue: any) {
    // 自身属性被增删改查时调用
    console.log(name, oldValue, newValue)
    let res = newValue
    try {
      res = JSON.parse(res)
    } catch (error) {}
    if (this[name] !== res) {
      this[name] = res
    }
  }

  get msg() {
    return  WalletLogin.getInstance().i18nMap
  }

  getFlag(c: string) {
    return 'flag:' + c.toUpperCase()
  }
  createDom() {
    const el = document.createElement('div')
    el.setAttribute('class', '__phone-input-root')

    const main = document.createElement('div')
    main.setAttribute('class', '__phone-input')
    const flag = this.getFlag(this.current.country)
    main.innerHTML = `
      <div class="__phone-input-l">
        <div class="__phone-input-l__country">
          <div class="country-flag ${flag}"></div>
        </div>
        <img src="${IMG_ARROW}" class="__phone-input-l__arrow"/>
      </div>

      <div class="__phone-input-r">
        <div class="__phone-input-r__prefix">
          ${this.current.prefix}
        </div>
        <div class='__phone-input-r__input-box'>
          <input class='__phone-input-r__input' placeholder="Mobile number" type="number"/>
        </div>

        <div class="__phone-input-r__send">
          ${this.msg.send}
        </div>
      </div>
    `
    el.innerHTML = `<ul class="__phone-input-ul" >
        ${this.createLis()}
    </ul>`

    el.append(main)

    this.append(el)
    this.countryBtnEl = document.querySelector('.__phone-input-l')
    this.inputEl = document.querySelector('.__phone-input-r__input')
    this.ulEl = document.querySelector('.__phone-input-ul')
    const confirmBtn = document.querySelector('.__phone-input-r__send')

    confirmBtn?.addEventListener('click', () => {
      // @ts-ignore
      if (this.inputEl.value.length < 7) {
        return
      }
      this.inputEl?.blur()
      WalletLogin.getInstance().connect({
        preferredAuthType: 'phone',
        // @ts-ignore
        account: this.current.prefix + this.inputEl.value,
        supportAuthTypes: 'all',
        socialLoginPrompt: 'consent',

        isSocial: true
      })
    })

    const toggleUl = () => {
      this.ulEl?.classList.toggle('active')
    }

    this.ulEl?.addEventListener('click', (e: any) => {
      const tags = [
        e.target,
        e.target.parentNode,
        e.target.parentNode.parentNode
      ]
      const target = tags.find((t) => t.tagName.toLowerCase() === 'li')
      if (target) {
        const row = target.getAttribute('data-row')
        this.current = parseString(row)
        toggleUl()
      }
    })
    this.countryBtnEl?.addEventListener('click', (e) => {
      toggleUl()
    })
    return el
  }

  createLis() {
    let res = ''

    defaultCountriesData.forEach((t) => {
      const [k1, k2, v] = t
      const obj = {
        country: k2,
        prefix: '+' + v
      }
      res += `
      <li class="__phone-input-ul__li" data-row=${JSON.stringify(obj)}>
        <div class="__phone-input-ul__li-l">
          <div class="_flag">
            <span class=" ${this.getFlag(k2)}"></span>
          </div>
          <div class="_country">${k1}</div>
         </div>
        <div class="__phone-input-ul__li-r">+${v}</div>
      </li>
      `
    })

    return res
  }

  changePage() {
    const { country, prefix } = this.current
    const flag = document.querySelector('.country-flag')
    flag?.setAttribute('class', `country-flag ${this.getFlag(country)}`)
    const pre = document.querySelector('.__phone-input-r__prefix')
    pre!.innerHTML = prefix || ''
  }
}

window.customElements.define('phone-input', PhoneInput)
